<template>
  <ion-modal :is-open="isOpen" mode="md" :backdrop-dismiss="true" :show-backdrop="true" @didDismiss="dismissModal">
    <modals-layout :data="{ title: modalTitle }" @dismiss-modal="dismissModal">
      <div class="main mt-1 d-flex flex-column overflow-auto">
        <div v-if="!loading">
          <div class="mt-3">
            <div class="d-flex align-items-center position-relative">
              <i class="icon pl-2 ti-search position-absolute" />
              <ion-input
                class="c-input one-input"
                :value="searchValue"
                placeholder="Search your worlds"
                @input="searchValue = $event.target.value"
              />
            </div>
          </div>
          <div class="mt-2">
            <div class="d-flex justify-content-end">
              <ion-button :color="buttonColor" class="reorder-button" @click="toggleReorder">
                {{ buttonContent }}
              </ion-button>
            </div>

            <Grid scrollable>
              <Sortable
                item-key="id"
                tag="div"
                class="sorting"
                :list="alreadySelectedWorlds"
                :options="sortableOptions"
                :class="{ dov: isReordering }"
                @end="handleReorder"
              >
                <template #item="{ element: world, index }">
                  <StoryWorldCard
                    :key="index"
                    class="selectable-grid-item"
                    :world="world"
                    :is-selected="isWorldSelected(world)"
                    image-only
                    is-selectable
                    is-removable
                    :is-reorderable="isReordering"
                    @select="select(world, $event)"
                  />
                </template>
              </Sortable>
            </Grid>
          </div>
          <div v-if="userWorlds.length" class="selectable-worlds mb-3 d-flex align-items-center">
            <Grid :lg="2" :md="2" :sm="1" :scrollSm="false">
              <StoryWorldCard
                v-for="(world, index) of presentedWorlds"
                :key="index"
                class="grid-item"
                :world="world"
                is-selectable
                :is-selected="isWorldSelected(world)"
                :limited="true"
                @select="select(world, $event)"
              />
            </Grid>
          </div>
          <div v-else class="no-data">No worlds</div>
        </div>
        <div v-else class="d-flex align-items-center justify-content-center">
          <ch-loading size="lg" />
        </div>
      </div>
      <div class="d-flex mt-1">
        <ion-custom-button
          :disabled="isSubmitting"
          :loading="isSubmitting"
          color="primary"
          class="mt-3 mr-2 action-btn"
          @click.stop="onSubmit"
          >Save</ion-custom-button
        >
        <ion-custom-button :disabled="isSubmitting" color="medium" class="mt-3 action-btn" @click="dismissModal"
          >Cancel</ion-custom-button
        >
      </div>
    </modals-layout>
  </ion-modal>
</template>

<script lang="ts" setup>
import { Collection, WorldsInfo } from '@/shared/types/static-types';
import { getAllStoryWorlds, getOwnStoryWorlds } from '@/shared/actions/worlds';
import { addWorldInCollection, removeObjectInCollection } from '@/shared/actions/collections';
import { isTouchScreen } from '@/shared/utils/ui';
import { toast } from '@/shared/native/toast';
import { authStore } from '@/shared/pinia-store/auth';
import Grid from '@/shared/components/storage/Grid.vue';
import StoryWorldCard from '@/shared/components/storage/StoryWorldCard.vue';

const searchValue = ref('');

const props = defineProps({
  modalTitle: {
    type: String,
    default: 'Add worlds to folder',
  },
  isOpen: {
    type: Boolean,
  },
  folder: {
    type: Object,
    default: () => ({}),
  },
  loading: {
    type: Boolean,
  },
  worlds: {
    type: Array,
    default: () => [],
  },
});

const buttonContent = computed(() => (isReordering.value ? 'Save order' : 'Reorder'));
const buttonColor = computed(() => (isReordering.value ? 'success' : 'primary'));

const worldsRef = toRef(props, 'worlds');

const sortableOptions = ref({
  handle: '.reorder-handle',
  animation: 150,
  delay: isTouchScreen() === false ? 0 : 200,
});

const isReordering = ref(false);
const innerLoading = ref(false);

const emits = defineEmits(['dismissModal']);

const alreadySelectedWorlds = ref<WorldsInfo[]>([]);
const folder = computed(() => props.folder as Collection);

const { user, isAuthenticated } = authStore();

const areWorldsLoading = ref(false);

const isSubmitting = ref(false);

const handleReorder = async (e: any) => {
  const { oldIndex, newIndex } = e;

  const element = alreadySelectedWorlds.value[oldIndex];
  alreadySelectedWorlds.value.splice(oldIndex, 1);
  alreadySelectedWorlds.value.splice(newIndex, 0, element);
};

const userWorlds = ref([]);

const dismissModal = () => {
  emits('dismissModal');

  isSubmitting.value = false;
};

const isWorldSelected = (world: WorldsInfo) => {
  return alreadySelectedWorlds.value.map(({ id }) => id!).includes(world?.id!);
};

const select = (world: WorldsInfo, isSelected: boolean) => {
  if (isSelected) {
    alreadySelectedWorlds.value.push(world!);
  }

  if (!isSelected) {
    alreadySelectedWorlds.value = alreadySelectedWorlds.value.filter(({ id: worldId }) => worldId !== world.id);
  }
};

const toggleReorder = async () => {
  try {
    const previouslySelectedWorldIds = (worldsRef.value as WorldsInfo[]).map(({ id }) => id!);
    const selectedWorldIds = alreadySelectedWorlds.value.map(({ id }) => id!);
    isReordering.value = !isReordering.value;
    innerLoading.value = true;
    if (!isReordering.value) {
      if (selectedWorldIds.toString() === previouslySelectedWorldIds.toString()) return;

      await removeObjectInCollection({
        collection: folder.value.id,
        ids: previouslySelectedWorldIds,
      });

      await addWorldInCollection({ id: folder.value.id, world_id: selectedWorldIds });
    }

    innerLoading.value = false;
  } finally {
    innerLoading.value = false;
  }
};

const onSubmit = async () => {
  isSubmitting.value = true;
  const previouslySelectedWorldIds = (worldsRef.value as WorldsInfo[])
    .map((item) => item.id)
    .filter((item): item is string => !!item);
  const selectedWorldIds = alreadySelectedWorlds.value.map(({ id }) => id!);

  try {
    if (previouslySelectedWorldIds.length) {
      await removeObjectInCollection({
        collection: folder.value.id,
        ids: previouslySelectedWorldIds,
      });
    }

    await addWorldInCollection({ id: folder.value.id, world_id: selectedWorldIds });

    dismissModal();
  } catch (_err) {
    await toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
    dismissModal();
  }

  isSubmitting.value = false;
};

const getUserWorlds = async () => {
  areWorldsLoading.value = true;

  userWorlds.value = (await getOwnStoryWorlds('story', 1, 1000)).results;
  areWorldsLoading.value = false;
};

watch(
  worldsRef,
  () => {
    alreadySelectedWorlds.value = cloneDeep(worldsRef.value as WorldsInfo[]);
  },
  { deep: true }
);

onMounted(() => {
  if (isAuthenticated.value) {
    getUserWorlds();
  }
  alreadySelectedWorlds.value = cloneDeep(worldsRef.value as WorldsInfo[]);
});

const presentedWorlds = computed(() =>
  searchValue.value
    ? userWorlds.value.filter((world: WorldsInfo) => world.name?.toLocaleLowerCase().includes(searchValue.value))
    : userWorlds.value
);
</script>

<style lang="sass" scoped>
.sorting
  grid-gap: 6px
  display: flex
.submit-btn
  width: 180px
  .spinner
    width: 15px
    height: 15px

ion-modal
  --width: 60%
  --height: 85%
  @media(min-width: 1100px)
    --width: 45% !important
  @media(max-width: 599px)
    --height: 85%
    --width: 85%

.main
  height: calc(100% - 3.25rem)
  overflow-x: hidden !important

.action-btn
  width: 90px !important
  margin-top: 0 !important
  ::v-deep
    *
      text-transform: unset !important
    .button
      height: 40px !important
</style>
