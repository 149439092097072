<template>
  <div class="wrap">
    <div v-if="isOwner" class="actions mt-3 mb-2 w-100 d-flex justify-content-between">
      <ion-button class="btn-action" @click.prevent="addCharacter"> Add/Remove Character </ion-button>
      <ion-button
        v-if="reorderable"
        :disabled="innerLoading"
        :color="buttonColor"
        class="btn-action reorder-button"
        @click="toggleReorder"
      >
        {{ buttonContent }}
      </ion-button>
    </div>
    <div v-if="characters && characters.length" class="characters mt-2">
      <Grid :xl="3" :lg="4" :md="3" :sm="2" :scrollSm="false">
        <Sortable
          item-key="id"
          tag="div"
          class="sorting"
          :list="characters"
          :options="sortableOptions"
          @end="handleReorder"
        >
          <template #item="{ element: character }">
            <CharacterCard
              :key="character.id"
              :limited="isReordering"
              :is-reorderable="isReordering"
              class="grid-item"
              :char-comments-count="get(charCommentsCount, `${get(character, 'id')}.comments_count`) || 0"
              :character="character"
            />
          </template>
        </Sortable>
      </Grid>

      <ion-button v-if="nextPageExists" class="clickable" @click="requestLoadMore">Load More</ion-button>
      <p v-if="!nextPageExists" class="no-data">End of list</p>
    </div>

    <div v-else class="no-data">No characters yet</div>
    <AddCharacterInFolder
      :is-open="isCharacterFormOpen"
      :loading="loading"
      :characters="characters"
      :folder="folder"
      @dismiss-modal="dismissModal"
    />
  </div>
</template>

<script lang="ts" setup>
import { Character, Collection } from '@/shared/types/static-types';
import AddCharacterInFolder from '../modals/AddCharacterInFolder.vue';
import CharacterCard from '@/shared/components/storage/CharacterCard.vue';
import Grid from '@/shared/components/storage/Grid.vue';
import { addCharacterInCollection, removeObjectInCollection } from '@/shared/actions/collections';
import { getCommentsCount } from '@/shared/actions/comments';
import constants from '@/shared/statics/constants';

const props = defineProps({
  isOwner: {
    type: Boolean,
  },
  currentTab: {
    type: String,
  },
  characters: {
    type: Array,
  },
  paging: {
    type: Object,
  },
  loading: {
    type: Boolean,
  },
  folder: {
    type: Object,
  },
});

const sortableOptions = ref({
  handle: '.reorder-handle',
  animation: 150,
});

const emits = defineEmits(['onLoadMore', 'reload', 'update', 'loadAll']);
const isCharacterFormOpen = ref(false);
const innerLoading = ref(false);
const charCommentsCount = ref({});
const reorderable = computed(() => {
  return characters.value.length > 1;
});

const addCharacter = () => {
  emits('loadAll');
  isCharacterFormOpen.value = true;
};

const dismissModal = () => {
  isCharacterFormOpen.value = false;
  emits('reload');
};

const characters = computed(() => props.characters as Character[]);
const paging = computed(() => props.paging as any);
const folder = computed(() => props.folder as Collection);

const isReordering = ref(false);
const mutatedSelectedCharacters = ref<Character[]>([]);

const initCharIds = () => {
  mutatedSelectedCharacters.value = cloneDeep(characters.value);
};

const fetchCharsCommentCounts = async () => {
  const resp = await getCommentsCount(map(characters.value, 'id') as string[], constants.commentedAsTypes.CHARACTER);
  charCommentsCount.value = keyBy(get(resp, 'counts', []), 'commented_on_object_id');
};

watch(characters, () => {
  initCharIds();
  fetchCharsCommentCounts();
});

onMounted(() => {
  initCharIds();
});

const toggleReorder = async () => {
  try {
    isReordering.value = !isReordering.value;
    innerLoading.value = true;

    if (!isReordering.value) {
      const prevSelectedCharacterIds = characters.value.map(({ id }: Character) => id!);
      const mutatedSelectedCharacterIds = mutatedSelectedCharacters.value.map(({ id }: Character) => id!);

      if (prevSelectedCharacterIds.toString() === mutatedSelectedCharacterIds.toString()) return;

      await removeObjectInCollection({
        collection: folder.value.id,
        ids: prevSelectedCharacterIds,
      });

      await addCharacterInCollection({ id: folder.value.id, character_id: mutatedSelectedCharacterIds });
      emits('update', mutatedSelectedCharacters);
    }

    innerLoading.value = false;
  } finally {
    innerLoading.value = false;
  }
};

const handleReorder = async (e: any) => {
  const { oldIndex, newIndex } = e;

  const element = mutatedSelectedCharacters.value[oldIndex];
  mutatedSelectedCharacters.value.splice(oldIndex, 1);
  mutatedSelectedCharacters.value.splice(newIndex, 0, element);
};

const buttonContent = computed(() => (isReordering.value ? 'Save order' : 'Reorder'));
const buttonColor = computed(() => (isReordering.value ? 'success' : 'primary'));

const nextPageExists = computed(() => {
  return !!paging.value.next;
});

const requestLoadMore = () => {
  emits('onLoadMore');
};
</script>

<style scoped lang="sass">
.sorting
  grid-gap: 6px
  display: flex
  overflow: hidden
  flex-wrap: wrap
  width: 100%
.btn-action
  text-transform: unset
  --border-radius: 12px
.reorder-button
    &.ion-color-primary
      --ion-color-base: #5727A0 !important
.characters
  color: #214163
</style>
