<template>
  <div class="w-100 position-relative d-flex flex-column align-items-center wrapper">
    <div class="cover">
      <img loading="lazy" v-image :src="cover" />
    </div>

    <div class="rest p-3 w-100 folder-header">
      <div v-if="isOwner" class="actions w-100">
        <div class="manage d-flex align-items-center justify-content-end">
          <div class="black-small" @click="manage"><i class="ti-more-alt" /></div>
          <div class="text ml-2" @click="manage">Manage folder</div>
        </div>
      </div>

      <div class="info d-flex align-items-center w-100 justify-content-start">
        <div class="image position-relative">
          <img loading="lazy" v-image class="position-absolute" :src="profileImg" />
          <inline-svg width="77px" src="/folder.svg" class="icon" />
        </div>
        <div class="name mt-2 ml-3">
          {{ name }} <span v-if="totalCount"> ({{ totalCount }}) </span>
        </div>
      </div>

      <div class="description mt-3" v-html="description" />
    </div>
  </div>

  <AddWorldInCollection :is-open="isWorldFormOpen" :collection-id="folder.id" @dismiss-modal="closeWorldForm" />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';

import AddCharacterInCollection from '../modals/AddCharacterInFolder.vue';
import AddWorldInCollection from '../modals/AddWorldInFolder.vue';
import FolderPopover from '../popovers/FolderPopover.vue';
import CollectionInfiniteCharacters from './InfiniteCharacters.vue';
import { Collection } from '@/shared/types/static-types';
import { authStore } from '@/shared/pinia-store/auth';
import { popovers } from '@/shared/native/popovers';
import { sanitizeHtml } from '@/shared/utils/html';

@Options({
  name: 'CollectionHeader',
  components: { AddCharacterInCollection, CollectionInfiniteCharacters, AddWorldInCollection },
})
export default class CollectionHeader extends Vue {
  @Prop() folder!: Collection;
  @Prop() totalCount!: number;

  public isCharacterFormOpen = false;
  public isWorldFormOpen = false;

  public get user() {
    const { user } = authStore();
    return user.value;
  }

  public get = get;

  public get cover() {
    return this.folder.banner_img_url;
  }

  public get count() {
    return this.folder.banner_img_url;
  }

  public get profileImg() {
    return this.folder.cropped_img_url;
  }

  public get username() {
    const { user } = authStore();
    return user.value.username;
  }

  public get isOwner() {
    return this.folder.user?.username === this.username;
  }

  public get profilePicture() {
    if (!this.folder || !this.folder.cropped_img_url) return '/empty.png';
    return this.folder.cropped_img_url;
  }

  public get name() {
    return this.folder.name;
  }

  public get description() {
    return sanitizeHtml(this.folder.description || '');
  }

  public async addCharacter() {
    this.isCharacterFormOpen = true;
  }

  public async addWorld() {
    this.isWorldFormOpen = true;
  }

  public manage(ev: CustomEvent) {
    popovers.open(ev, FolderPopover, { folder: this.folder });
  }

  @Emit('addCharacter')
  public closeCharacterForm() {
    this.isCharacterFormOpen = false;
  }

  @Emit('addWorld')
  public closeWorldForm() {
    this.isWorldFormOpen = false;
  }
}
</script>

<style lang="sass" scoped>
.rest
  background: #E6E6E6
.image
  width: 80px
  height: 76px
  .icon
    z-index: 11
    position: relative
  img
    width: 100%
    height: 100%
    object-fit: cover
    clip-path: polygon(4px 9px, calc(100% - 54px) 6px, calc(100% - 39px) 16px, calc(100% - 6px) calc(100% - 59px), calc(100% - 5px) calc(100% - 5px), 39px 90%, 2px 90%, 3px 61px)
.actions
  .manage
    font-weight: bold
    font-size: 14px
    .black-small, .text
      cursor: pointer
.wrapper
  .black-small
    background: #0A0928E5
    width: 20px
    height: 20px
    color: #FFF
    display: flex
    justify-content: center
    align-items: center
    font-size: 12px
    border-radius: 6px
  .cover
    height: 260px
    width: 100%
    background-position: center
    background-size: cover
    background-repeat: no-repeat
    box-shadow: 1px 2px 5px 0px rgba(1,1,35,0.19)
    z-index: 1
    img
      width: 100%
      height: 100%
      object-fit: cover

  .name
    position: relative
    font-size: 32px
    font-weight: bold

    .edit
      font-size: 14px
      color: #7f10b3
      font-weight: bold
  .description
    position: relative
    border-radius: 10px
.desc
  margin-top: 0.75rem
</style>
