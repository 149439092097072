<template>
  <div class="tabs">
    <ion-segment
      scrollable
      mode="ios"
      color="primary"
      class="tabs compact no-shadow bg-transparent"
      :value="activeIndex"
      @ionChange="segmentChanged($event)"
    >
      <ion-segment-button v-for="(tab, index) of tabs" :key="index" class="shrink bg-transparent" :value="index">
        <ion-label
          >{{ tab.name }}<span v-if="count && tab.value === 'characters'"> ({{ count }})</span>
          <span v-else-if="worldCount && tab.value === 'storyworlds'"> ({{ worldCount }})</span>
        </ion-label>
      </ion-segment-button>
    </ion-segment>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Tab } from '@/shared/types/static-types';

@Options({
  name: 'CharactersTabs',
  components: {},
})
export default class CharactersTabs extends Vue {
  @Prop({ type: Array }) public tabs!: Tab[];
  @Prop() public count!: any;
  @Prop() public worldCount!: any;

  public activeIndex = 0;

  public segmentChanged($event: CustomEvent) {
    this.activeIndex = $event.detail.value as number;
    const tab = this.tabs[this.activeIndex];
    this.$emit('tab-changed', tab);
  }
}
</script>

<style scoped lang="sass">
.tabs
  background: transparent
  min-height: 30px !important
  ::v-deep
    ion-segment
      margin: 0 !important
      border-bottom: 2px solid #214163
      border-radius: 0
      height: 30px
      ion-segment-button
        height: 30px !important
        min-height: 30px !important
        background: transparent
        &::part(indicator)
          opacity: 0 !important
    .segment-button-checked
      border-radius: 12px 12px 0 0
      background: #214163 !important
      *
        color: #FFF
.shrink
  flex: none !important
</style>
