<template>
  <ion-page class="page scrollable no-padding">
    <Header :folder="folder" :total-count="totalCount" />
    <div class="rest p-3 mt-3">
      <div class="title">Subfolders inside this Folder</div>
      <div>
        <div v-if="isAuthenticated && isFolderOwner" class="actions mt-3 mb-2 w-100 d-flex justify-content-between">
          <ion-button class="btn-action" @click.prevent="addFolder"> Add/Remove Subfolder </ion-button>
          <ion-button
            v-if="reorderable"
            :disabled="loading"
            :color="buttonColor"
            class="btn-action reorder-button"
            @click="toggleReorder"
          >
            {{ buttonContent }}
          </ion-button>
        </div>
      </div>
      <Grid v-if="folders?.length" :lg="4" :md="3" :sm="2">
        <Sortable
          item-key="id"
          tag="div"
          class="sorting"
          :list="folders"
          :options="sortableOptions"
          @end="handleReorder"
        >
          <template #item="{ element: subfolder }">
            <FolderCard
              :key="subfolder.id"
              :limited="isReordering"
              class="grid-item"
              :is-reorderable="isReordering"
              :folder="subfolder"
              :is-selectable="false"
            />
          </template>
        </Sortable>
      </Grid>
      <div v-else class="no-data p-3">No subfolders</div>
    </div>
    <div class="rest p-3 mt-3">
      <div class="title">Creations</div>
      <CharactersTabs class="mt-4" :tabs="tabs" :count="count" :world-count="worldCount" @tab-changed="tabChanged" />
      <InfiniteCharacters
        :isOwner="isFolderOwner"
        v-show="currentTab === 'characters'"
        :characters="characters"
        :folder="folder"
        :paging="chpaging"
        :loading="loading"
        @onLoadMore="loadMoreCharacters"
        @update="updateCharacters"
        @loadAll="fetchCharactersCollection(true)"
        @reload="fetchCharactersCollection"
      />

      <InfiniteWorlds
        :isOwner="isFolderOwner"
        v-show="currentTab === 'storyworlds'"
        :folder="folder"
        :worlds="worlds"
        :loading="loading"
        :paging="worldspaging"
        @reload="fetchWorldsCollection"
        @onLoadMore="loadMoreWorlds"
        @loadAll="fetchWorldsCollection(true)"
        @update="updateWorlds"
      />
    </div>
    <AddFolderInFolder
      :is-open="isFoldersSelectionOpen"
      :folders="folders"
      :folder="folder"
      :loading="loading"
      @dismiss-modal="dismissModal"
      @loadAll="fetchFoldersInFolder(true)"
      @reload="fetchFoldersInFolder"
    />
  </ion-page>
</template>

<script lang="ts" setup>
import Header from './components/Header.vue';
import CharactersTabs from './components/CharactersTabs.vue';
import InfiniteCharacters from './components/InfiniteCharacters.vue';
import InfiniteWorlds from './components/InfiniteWorlds.vue';
import AddFolderInFolder from './modals/AddFolderInFolder.vue';
import { toast } from '@/shared/native';
import { Collection, Tab } from '@/shared/types/static-types';
import {
  addFoldersInsideFolder,
  getCharactersForCollection,
  getCollectionFromSlug,
  getFoldersInsideFolder,
  getWorldsForCollection,
  removeObjectInCollection,
} from '@/shared/actions/collections';
import { collectionTabs } from '@/shared/statics/tabs';
import { isTouchScreen } from '@/shared/utils/ui';
import FolderCard from '@/shared/components/storage/FolderCard.vue';
import Grid from '@/shared/components/storage/Grid.vue';
import { authStore } from '@/shared/pinia-store/auth';
const { isAuthenticated, user: loggedInUser } = authStore();
const folder = ref<Collection>({
  id: '',
});

const updateCharacters = (chs: any) => {
  characters.value = chs.value;
};

const updateWorlds = (wds: any) => {
  worlds.value = wds.value;
};

const tabs = ref(collectionTabs);
const currentTab = ref('characters');
const isReordering = ref(false);
const loading = ref(false);
const picture = ref('');
const title = ref('');
const description = ref('');
const pageTitle = ref('');
const isPublic = ref(true);
const isFoldersSelectionOpen = ref(false);
const route = useRoute();
const url = ref(`https://characterhub.com${route.path}`);

const totalCount = computed(() => count.value + worldCount.value + foldersCount.value);

const buttonContent = computed(() => (isReordering.value ? 'Save order' : 'Reorder'));
const buttonColor = computed(() => (isReordering.value ? 'success' : 'primary'));

const addFolder = () => {
  fetchFoldersInFolder(true);
  isFoldersSelectionOpen.value = true;
};

const isFolderOwner = computed(() => {
  return folder.value.user?.username === loggedInUser.value.username;
});

const reorderable = computed(() => {
  return folders.value.length > 1;
});

const dismissModal = () => {
  isFoldersSelectionOpen.value = false;
};

const toggleReorder = async () => {
  try {
    isReordering.value = !isReordering.value;
    loading.value = true;

    if (!isReordering.value) {
      await removeObjectInCollection({
        collection: folder.value.id,
        ids: folders.value.map(({ id }: any) => id),
      });
      await addFoldersInsideFolder(
        folder.value.id,
        folders.value.map(({ id }) => id)
      );
    }

    loading.value = false;
  } finally {
    setTimeout(() => {
      loading.value = false;
    }, 2000);
  }
};

const sortableOptions = ref({
  handle: '.reorder-handle',
  animation: 150,
  delay: isTouchScreen() === false ? 0 : 200,
});

const handleReorder = (e: any) => {
  const { oldIndex, newIndex } = e;
  const element = folders.value[oldIndex];
  folders.value.splice(oldIndex, 1);
  folders.value.splice(newIndex, 0, element);
};

const characters = ref<any[]>([]);

const worlds = ref<any[]>([]);
const folders = ref<any[]>([]);
const chpaging = ref({});
const worldspaging = ref({});
const folderspaging = ref({});
const count = ref(0);
const worldCount = ref(0);
const foldersCount = ref(0);
const currPage = ref(1);
const worldsCurrPage = ref(1);
const foldersCurrPage = ref(1);

const tabChanged = ({ value }: Tab) => {
  currentTab.value = value;
};

const fetchCollection = async () => {
  const router = useRouter();
  try {
    const response = await getCollectionFromSlug(router.currentRoute.value.params.slug as string);
    folder.value = response;

    fetchCharactersCollection();
    fetchWorldsCollection();
    fetchFoldersInFolder();
  } catch (e) {
    await toast.show(`Couldn't load folder`, 'nonative', 'danger');
    router.go(-1);
  }
};

const fetchCharactersCollection = async (all = false) => {
  const router = useRouter();
  try {
    loading.value = true;
    const { results, ...paging } = await getCharactersForCollection(
      folder.value.id,
      all ? 1 : undefined,
      all ? 999 : undefined
    );
    characters.value = cloneDeep(results);
    count.value = paging.count;
    chpaging.value = paging;
    currPage.value = 1;
  } catch (e) {
    await toast.show(`Couldn't load folder`, 'nonative', 'danger');
    router.go(-1);
  } finally {
    loading.value = false;
  }
};

const fetchWorldsCollection = async (all = false) => {
  const router = useRouter();
  try {
    loading.value = true;
    const { results, ...paging } = await getWorldsForCollection(
      folder.value.id,
      all ? 1 : undefined,
      all ? 999 : undefined
    );
    worlds.value = cloneDeep(results);
    worldCount.value = paging.count;
    worldspaging.value = paging;
    worldsCurrPage.value = 1;
  } catch (e) {
    await toast.show(`Couldn't load folder`, 'nonative', 'danger');
    router.go(-1);
  } finally {
    loading.value = false;
  }
};

const fetchFoldersInFolder = async (all = false) => {
  const router = useRouter();
  try {
    loading.value = true;
    const { results, ...paging } = await getFoldersInsideFolder(
      folder.value.id,
      all ? 1 : undefined,
      all ? 999 : undefined
    );
    folders.value = cloneDeep(results);
    folderspaging.value = paging;
    foldersCount.value = paging.count;
    foldersCurrPage.value = 1;
  } catch (e) {
    await toast.show(`Couldn't load folder`, 'nonative', 'danger');
    router.go(-1);
  } finally {
    loading.value = false;
  }
};

const loadMoreCharacters = async () => {
  try {
    currPage.value += 1;
    const { results, ...paging } = await getCharactersForCollection(folder.value.id, currPage.value);
    characters.value = characters.value.concat(results);
    chpaging.value = paging;
  } catch (e) {
    await toast.show(`Couldn't load more characters. Please try again.`, 'nonative', 'danger');
  }
};

const loadMoreWorlds = async () => {
  try {
    worldsCurrPage.value += 1;
    const { results, ...inpaging } = await getWorldsForCollection(folder.value.id, worldsCurrPage.value);
    worlds.value = worlds.value.concat(results);
    worldspaging.value = inpaging;
  } catch (e) {
    await toast.show(`Couldn't load more worlds. Please try again.`, 'nonative', 'danger');
  }
};

await useChAsyncData(async () => {
  await fetchCollection();
  pageTitle.value = `${folder.value.name} - CharacterHub`;
  description.value = `Read more about ${folder.value.name} on CharacterHub!`;
  title.value = `${folder.value.name}`;
  picture.value = folder.value.image_url || folder?.value?.banner_img_url || '';
  isPublic.value = !!(folder?.value as any)?.is_public;

  useChHead(pageTitle, title, description, url, picture, isPublic);
});

onMounted(() => {
  const document = useDocument();
  document.value?.getElementById('app-nav-bar')?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
});
</script>
<style lang="sass" scoped>
.reorder-button
  &.ion-color-primary
    --ion-color-base: #5727A0 !important
.btn-action
  text-transform: unset
  --border-radius: 12px
.sorting
  grid-gap: 6px
  display: flex
  flex-wrap: wrap
  width: 100%
.title
  font-size: 20px
  font-weight: bold
</style>
