<template>
  <div class="wrap">
    <div v-if="isOwner" class="actions mt-3 mb-2 w-100 d-flex justify-content-between">
      <ion-button class="btn-action" @click.prevent="addWorld"> Add/Remove World </ion-button>
      <ion-button
        v-if="reorderable"
        :disabled="innerLoading"
        :color="buttonColor"
        class="btn-action reorder-button"
        @click="toggleReorder"
      >
        {{ buttonContent }}
      </ion-button>
    </div>
    <div v-if="worlds && worlds.length" class="worlds mt-2">
      <Grid :scrollSm="false" :lg="2" :md="2" :sm="1">
        <Sortable
          item-key="id"
          tag="div"
          class="sorting"
          :list="worlds"
          :options="sortableOptions"
          @end="handleReorder"
        >
          <template #item="{ element: world }">
            <StoryWorldCard
              :key="world.id"
              :limited="isReordering"
              :is-reorderable="isReordering"
              class="grid-item"
              :world="world"
            />
          </template>
        </Sortable>
      </Grid>

      <p v-show="nextPageExists" class="clickable" @click="requestLoadMore">Load More</p>
      <p v-show="!nextPageExists" class="no-data">End of list</p>
    </div>

    <div v-else class="no-data">No worlds yet</div>
    <AddWorldInFolder
      :loading="loading"
      :is-open="isWorldFormOpen"
      :worlds="worlds"
      :folder="folder"
      @dismiss-modal="dismissModal"
    />
  </div>
</template>

<script lang="ts" setup>
import AddWorldInFolder from '../modals/AddWorldInFolder.vue';
import StoryWorldCard from '@/shared/components/storage/StoryWorldCard.vue';
import Grid from '@/shared/components/storage/Grid.vue';
import { addWorldInCollection, removeObjectInCollection } from '@/shared/actions/collections';
import { WorldsInfo } from '@/shared/types/static-types';

const props = defineProps({
  isOwner: {
    type: Boolean,
  },
  loading: {
    type: Boolean,
  },
  currentTab: {
    type: String,
    default: '',
  },
  worlds: {
    type: Array,
    default: () => [],
  },
  paging: {
    type: Object,
    default: () => ({}),
  },
  folder: {
    type: Object,
    default: () => ({}),
  },
});

const worlds = toRef(props, 'worlds');
const paging = toRef(props, 'paging');
const folder = toRef(props, 'folder');

const isReordering = ref(false);
const mutatedSelectedWorlds = ref<WorldsInfo[]>([]);

const toggleReorder = async () => {
  try {
    isReordering.value = !isReordering.value;
    innerLoading.value = true;
    const mutatedSelectedWorldsIds = mutatedSelectedWorlds.value.map(({ id }) => id);
    const previouslySelectedWorldIds = worlds.value.map(({ id }: any) => id);

    if (!isReordering.value) {
      if (mutatedSelectedWorldsIds.toString() === previouslySelectedWorldIds.toString()) return;

      await removeObjectInCollection({
        collection: folder.value.id,
        ids: previouslySelectedWorldIds,
      });

      await addWorldInCollection({ id: folder.value.id, world_id: mutatedSelectedWorldsIds });

      emits('update', mutatedSelectedWorlds);
    }

    innerLoading.value = false;
  } finally {
    innerLoading.value = false;
  }
};

const sortableOptions = ref({
  handle: '.reorder-handle',
  animation: 150,
});

const handleReorder = async (e: any) => {
  const { oldIndex, newIndex } = e;

  const element = mutatedSelectedWorlds.value[oldIndex];
  mutatedSelectedWorlds.value.splice(oldIndex, 1);
  mutatedSelectedWorlds.value.splice(newIndex, 0, element);
};

const emits = defineEmits(['onLoadMore', 'reload', 'update', 'loadAll']);
const isWorldFormOpen = ref(false);
const innerLoading = ref(false);

const reorderable = computed(() => {
  return worlds.value.length > 1;
});

const addWorld = () => {
  emits('loadAll');
  isWorldFormOpen.value = true;
};

const dismissModal = () => {
  isWorldFormOpen.value = false;
  emits('reload');
};

const initCharIds = () => {
  mutatedSelectedWorlds.value = cloneDeep(worlds.value) as any[];
};

watch(worlds, () => {
  initCharIds();
});

onMounted(() => {
  initCharIds();
});

const buttonContent = computed(() => (isReordering.value ? 'Save order' : 'Reorder'));
const buttonColor = computed(() => (isReordering.value ? 'success' : 'primary'));

const nextPageExists = computed(() => {
  return !!paging.value.next;
});

const requestLoadMore = () => {
  emits('onLoadMore');
};
</script>

<style scoped lang="sass">
.sorting
  grid-gap: 6px
  display: flex
  overflow: hidden
  flex-wrap: wrap
  width: 100%
  .grid-item
    width: calc(50% - 4px)
    min-width: calc(50% - 4px)
    @media(max-width: 520px)
      width: calc(100% - 4px)
      min-width: calc(100% - 4px)
.btn-action
  text-transform: unset
  --border-radius: 12px
.reorder-button
    &.ion-color-primary
      --ion-color-base: #5727A0 !important
.worlds
  color: #214163
</style>
